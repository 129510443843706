import { createGlobalStyle } from 'styled-components'
import { primaryFont } from './styleVariables'

export const GlobalStyle = createGlobalStyle`
    :root {
        --step--2: clamp(0.91rem, 0.89rem + 0.10vw, 0.96rem);
        --step--1: clamp(1.09rem, 1.05rem + 0.21vw, 1.20rem);
        --step-0: clamp(1.31rem, 1.24rem + 0.37vw, 1.50rem);
        --step-1: clamp(1.58rem, 1.46rem + 0.59vw, 1.88rem);
        --step-2: clamp(1.89rem, 1.71rem + 0.89vw, 2.34rem);
        --step-3: clamp(2.27rem, 2.01rem + 1.29vw, 2.93rem);
        --step-4: clamp(2.72rem, 2.36rem + 1.83vw, 3.66rem);
        --step-5: clamp(3.27rem, 2.75rem + 2.56vw, 4.58rem);
    }

    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    body, a {
        color: #444444;
        font-family: ${primaryFont};
        overflow-x: hidden;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    } 

    /* Firefox */
    input[type=number] {
    -moz-appearance: textfield;
    }

    ul {
        list-style-type: none;
    }
`
