import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { FaPhoneAlt } from 'react-icons/fa'
import { GrMail } from 'react-icons/gr'
import { BsInstagram } from 'react-icons/bs'
import { primaryColor } from '../styles/styleVariables'
import { navData } from '../data/headerData'

const Footer = () => {
  return (
    <FooterContainer>
      <InfoSection>
        <Link to='/'>
          <StaticImage
            src='../images/logo.png'
            alt='WDR Logo'
            placeholder='blurred'
            layout='fixed'
            width={200}
          />
        </Link>
        <FooterQuote>Delivering Elite Plumbing Services</FooterQuote>
        <ul>
          <ListItem>
            <FaPhoneAlt />{' '}
            <ExtLink href='tel:+16044424470'>604-442-4470</ExtLink>
          </ListItem>
          <ListItem>
            <MailIcon />{' '}
            <ExtLink href='mailto:wdrcontracting@gmail.com'>
              wdrcontracting@gmail.com
            </ExtLink>
          </ListItem>
        </ul>
      </InfoSection>
      <PagesContainer>
        <SectionTitle>Quick Links</SectionTitle>
        <ListItems>
          {navData.map((item, index) => (
            <ListItem key={index}>
              <GatLink to={item.link}>{item.title}</GatLink>
            </ListItem>
          ))}
        </ListItems>
      </PagesContainer>
      <SocialContainer>
        <SectionTitle>Follow Us</SectionTitle>
        <ul>
          <li>
            <a href='https://www.instagram.com/wdrcontracting/'>
              <IconWrapper>
                <BsInstagram />
              </IconWrapper>
            </a>
          </li>
        </ul>
      </SocialContainer>
    </FooterContainer>
  )
}

export default Footer

const FooterContainer = styled.footer`
  padding: 2rem max(calc((100vw - 1200px) / 2), 1rem);
  background-color: #f9f9fb;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;

  @media screen and (min-width: 880px) {
    flex-direction: row;
    justify-content: space-between;
  }
`

const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
`

const FooterQuote = styled.span`
  margin: 1rem 0 2rem 0;
  font-size: var(--step--2);
`

const PagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`

const SectionTitle = styled.h3`
  font-size: var(--step-0);
  font-weight: 600;
  margin-bottom: 0.5rem;
`
const SocialContainer = styled.div``

const ExtLink = styled.a`
  text-decoration: none;
  margin-left: 0.5rem;
  word-wrap: break-word;

  &:hover {
    color: ${primaryColor};
  }
`

const ListItems = styled.ul`
  display: flex;
  flex-direction: column;
`

const ListItem = styled.li`
  font-size: var(--step--1);
  margin-bottom: 0.25rem;

  @media screen and (min-width: 880px) {
    font-size: var(--step--2);
  }
`

const GatLink = styled(Link)`
  text-decoration: none;
  text-transform: capitalize;

  &:hover {
    color: ${primaryColor};
  }
`

const IconWrapper = styled.span`
  font-size: var(--step-1);
  cursor: pointer;

  &:hover {
    color: ${primaryColor};
  }
`

const MailIcon = styled(GrMail)`
  vertical-align: middle;
  font-size: 1.15rem;
`
