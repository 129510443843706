import React from 'react'
import styled from 'styled-components'
import { FaTimes } from 'react-icons/fa'
import { navData } from '../data/headerData'
import { Link } from 'gatsby'
import { primaryColor } from '../styles/styleVariables'
import { ExternalButton } from './Button'

const MobileMenu = ({ isOpen, toggle }) => {
  return (
    <MenuContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <MenuWrapper>
        <MenuList>
          <div>
            <ExternalButton color='blueFill' href='tel:+16044424770'>
              604-442-4770
            </ExternalButton>
          </div>
          {navData.map((item, index) => (
            <MenuLink key={index} to={item.link} onClick={toggle}>
              {item.title}
            </MenuLink>
          ))}
        </MenuList>
      </MenuWrapper>
    </MenuContainer>
  )
}

export default MobileMenu

const MenuContainer = styled.aside`
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: white;
  display: grid;
  align-items: center;
  top: 0;
  left: 0;
  transition: 0.3s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? '95%' : '0')};
  top: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
  overflow-y: hidden;
`

const Icon = styled.div`
  position: absolute;
  top: 1.2rem;
  right: 1.2rem;
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
`

const CloseIcon = styled(FaTimes)`
  color: #44444;
  :hover {
    color: ${primaryColor};
  }
`

const MenuWrapper = styled.div`
  color: #444444;
`

const MenuList = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(6, 60px);
  text-align: center;
  padding: 0;
`

const MenuLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: bold;
  list-style: none;
  transition: 0.2s ease-in-out;
  color: #44444;
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: none;

  :hover {
    color: ${primaryColor};
  }
`
