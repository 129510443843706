import React from 'react'
import styled from 'styled-components'
import { primaryColor, primaryFont } from '../styles/styleVariables'
import { TitleH2 } from './Titles'

export const ContactForm = () => {
  return (
    <FormWrapper>
      <FormText>
        <TitleH2>Contact Us</TitleH2>
        <span>Quotes, questions, concerns, comments?</span>
        <span>
          Use this form or email us at: <b>wdrcontracting@gmail.com </b>
          Or call us at:
          <b> 604-442-4770</b>
        </span>
      </FormText>
      <Form method='POST' action='https://api.web3forms.com/submit'>
        <input
          type='hidden'
          name='access_key'
          value='677fab21-e981-48a0-b43e-92a4e2a9e1d1'
        />
        <input
          type='checkbox'
          name='botcheck'
          class='hidden'
          style={{ display: 'none' }}
        ></input>
        <FormLine>
          <FormInput
            type='text'
            name='first-name'
            placeholder='First Name'
            required
          />
          <FormInput
            type='text'
            name='last-name'
            placeholder='Last Name'
            required
          />
        </FormLine>
        <FormLine>
          <FormInput
            type='tel'
            pattern='[0-9]{10}'
            maxlength='10'
            name='phone'
            placeholder='Phone Number'
            required
          />
          <FormInput type='email' name='email' placeholder='Email' required />
        </FormLine>
        <TextWrapper>
          <FormTextArea name='message' placeholder='Message' required />
        </TextWrapper>
        <BtnWrapper>
          <SubmitBtn type='submit' value='Send Message' />
        </BtnWrapper>
      </Form>
    </FormWrapper>
  )
}

export default ContactForm

const FormWrapper = styled.div`
  background-color: ${primaryColor};
  width: 100%;
  padding: 5rem 2rem;
  color: white;
  margin-top: 2rem;

  @media screen and (min-width: 1335px) {
    display: flex;
    justify-content: space-around;
  }
`

const FormText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > span {
    text-align: center;
    margin-bottom: 1rem;
  }

  > * {
    &:first-child {
      margin-bottom: 1rem;
    }
  }

  @media screen and (min-width: 1335px) {
    width: 40%;
    align-items: flex-start;
    padding: 1rem 0;

    > span {
      text-align: left;
      max-width: 20rem;
    }
  }
`

const SubmitBtn = styled.input`
  background-color: white;
  color: #444444;
  border: 3px solid white;
  padding: 0.65rem 1.25rem;
  font-size: 15px;
  cursor: pointer;
  transition: 0.3s !important;
  text-transform: uppercase;
  border-radius: 8px;
  font-weight: 600;
  transition: 0.3s ease-in-out;

  &:hover {
    background-color: transparent;
    color: white;
    transform: translateY(-2px);
  }
`

const BtnWrapper = styled.div``

const FormLine = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 720px) {
    > input + input {
      margin-left: 1rem;
    }
    flex-direction: row;
  }
`

const FormInput = styled.input`
  padding: 1rem;
  outline: none;
  border: none;
  margin-bottom: 1rem;
  border-radius: 8px;
  font-family: ${primaryFont};
  width: 100%;
`

const FormTextArea = styled.textarea`
  resize: none;
  height: 10rem;
  font-family: ${primaryFont};
  padding: 1rem;
  outline: none;
  border: none;
  border-radius: 8px;
  box-sizing: border-box;
  width: 100%;
`

const TextWrapper = styled.div`
  padding: 0;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;

  > * {
    &:last-child {
      margin-top: 1rem;
      align-self: center;
    }
  }

  @media screen and (min-width: 1335px) {
    width: 50%;
  }
`
