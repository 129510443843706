import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { navData } from '../data/headerData'
import { Link } from 'gatsby'
import { ExternalButton } from './Button'
import { primaryColor } from '../styles/styleVariables'
import { FaBars } from 'react-icons/fa'

const Header = ({ toggle }) => {
  return (
    <Nav>
      <Link to='/'>
        <StaticImage
          src='../images/logo.png'
          alt='WDR Logo'
          placeholder='blurred'
          layout='fixed'
          width={250}
        />
      </Link>

      <NavMenu>
        {navData.map((item, index) => (
          <NavLink to={item.link} key={index}>
            {item.title}
          </NavLink>
        ))}
        <ExternalButton color='blueFill' href='tel:+16044424770'>
          604-442-4770
        </ExternalButton>
      </NavMenu>
      <MobileIcon onClick={toggle} />
    </Nav>
  )
}

export default Header

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem max(calc((100vw - 1400px) / 2), 1rem);
`

const NavMenu = styled.div`
  display: flex;
  align-items: center;
  > * {
    &:last-child {
      margin-left: 1rem;
    }
  }

  @media screen and (max-width: 1050px) {
    display: none;
  }
`

const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  margin: 0 1.5rem;
  font-size: 15px;
  text-transform: uppercase;
  transition: 0.3s;
  font-weight: 600;

  &:hover {
    color: ${primaryColor};
  }
`

const MobileIcon = styled(FaBars)`
  display: none;
  font-size: 2rem;
  cursor: pointer;

  &:hover {
    color: ${primaryColor};
  }
  @media screen and (max-width: 1050px) {
    display: inline;
  }
`
