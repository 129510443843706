import styled from 'styled-components'
import { primaryColor } from '../styles/styleVariables'

export const TitleH2 = styled.h2`
  font-size: var(--step-1);
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
`

export const TitleH1 = styled.h1`
  color: white;
  text-transform: uppercase;
  font-size: var(--step-4);
  font-weight: 500;
  line-height: 70px;
`

export const PageTitle = styled.h1`
  font-weight: 600;
  text-transform: uppercase;
  font-size: var(--step-2);
  margin: 3rem 0 1rem 0;
`
export const SubTitle = styled.h2`
  color: ${primaryColor};
  font-weight: 500;
  font-size: var(--step-0);
  text-align: center;
`
