import styled from 'styled-components'
import { Link } from 'gatsby'
import { primaryColor } from '../styles/styleVariables'

const handleColorType = (color) => {
  switch (color) {
    case 'blueFill':
      return `background-color: ${primaryColor}; color: white; border: 3px solid ${primaryColor}; &:hover { background-color: transparent; color: ${primaryColor};`
    case 'whiteFill':
      return 'background-color: white; color: #444444; border: 3px solid white; &:hover { background-color: transparent; color: white;'
    case 'whiteTrans':
      return `background-color: transparent; text-decoration: underline; text-underline-offset: 10px; text-decoration-thickness: 2px; &:hover { color: ${primaryColor};`
    //no default
  }
}

export const Button = styled(Link)`
  padding: ${({ big }) => (big ? '0.75rem 2rem' : '0.5rem 1.25rem')};
  font-size: ${({ big }) => (big ? '20px' : '15px')};
  outline: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
  transition: 0.3s !important;
  text-transform: uppercase;
  border-radius: 8px;
  font-weight: 600;
  ${({ color }) => handleColorType(color)};
  &:hover {
    transform: translateY(-2px);
  }
`

export const ExternalButton = styled.a`
  padding: ${({ big }) => (big ? '0.75rem 2rem' : '0.5rem 1.25rem')};
  font-size: ${({ big }) => (big ? '20px' : '15px')};
  outline: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
  transition: 0.3s !important;
  text-transform: uppercase;
  border-radius: 8px;
  font-weight: 600;
  ${({ color }) => handleColorType(color)};
  &:hover {
    transform: translateY(-2px);
  }
`
