import React, { useState } from 'react'
import styled from 'styled-components'
import { GlobalStyle } from '../styles/globalStyles'
import { primaryFont } from '../styles/styleVariables'
import Footer from './Footer'
import Header from './Header'
import MobileMenu from './MobileMenu'

const Layout = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <GlobalStyle />
      <Header toggle={toggle} />
      <MobileMenu isOpen={isOpen} toggle={toggle} />
      <MainWrapper isOpen={isOpen}>{children}</MainWrapper>
      <Footer />
    </>
  )
}

export default Layout

const MainWrapper = styled.main`
  padding: 0rem max(calc((100vw - 1300px) / 2), 1rem);
  font-family: ${primaryFont}, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
`
